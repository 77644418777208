import {
  animated,
  Spring,
  config,
} from "react-spring";
import NavbarButton from "./NavbarButton"
import React from "react";
import "./Navbar.css"
import { eventBus } from "./EventBus";

export default class Navbar extends React.Component {
    constructor(props) {
      super(props);

      this.state = { 
        visible: this.props.visible,
        wide: true
      };
      this.ref = React.createRef();
      eventBus.on("navbarWidth", (data) => this.setState({wide: data}));
    }
  
    render() {
      const { visible } = this.props;
      return (
        <Spring
        transform={visible ? "translate3d(0,1rem,0)" : "translate3d(0,-1rem,0)"}
        opacity={visible ? "1" : "0"}
        config={{mass: 1.0, tension: 220, friction: 24.0}}
        >
          {(styles) => (
            <animated.div style={styles} className="listContainer">
                <Spring
                opacity={this.state.wide ? "0" : "1"}
                config={{mass: 1.0, tension: 120, friction: 8.0, clamp: true}}
                >
                  {(logoStyle) => (
                    <animated.h1 style={logoStyle} className="Logo">HUU QUANG NGUYEN</animated.h1>
                  )}
                </Spring>
                <ul className="Navbar">
                  {this.props.items.map((i, idx) => {
                      return (
                        <NavbarButton
                          item={i}
                          index={idx}
                          key={idx}
                          props={this.props}
                          style={styles}
                          // state={this.state.itemStates[idx]}
                          // updateList={this.updateList}
                        />
                      );
                    })
                  }
                </ul>
              </animated.div>
            )}
        </Spring>
      );
    }
  }