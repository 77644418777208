import React from "react";
import { Spring, animated } from "react-spring";
import "./Button.css";

export default class Button extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  setHoverState = (state) => {
    this.setState({ hovered: state });
  };

  render() {
    return (
      <Spring
        outlineColor={this.state.hovered ? "rgba(89,54,4,1)" : "rgba(89,54,4, 0)"}
        // backgroundColor={this.state.hovered ? "rgba(230, 157, 41, 1)" : "rgba(230,157,41,255)"}
        fontWeight={this.state.hovered ? "600" : "400"}
        background={this.state.hovered ?  "radial-gradient(circle, rgba(230,157,41,1) 0%, rgba(195,130,25,1) 60%)" : 
                                          "radial-gradient(circle, rgba(195,130,25,1) 0%, rgba(230,157,41,1) 60%)"}
        color={this.state.hovered ? "rgba(84, 57, 15, 1)" : "rgba(232, 226, 218, 255)"}
        config={{
          tension: 150,
          friction: 14,
          clamp: true
        }}
      >
        {(styles) => (
            <animated.button
              ref={(btnRef) => (this.btn = btnRef)}
              style={styles}
              className="btn"
              onClick={this.props.disabled ? () => {} : this.props.onClick}
              onMouseEnter={() => this.setHoverState(true)}
              onMouseLeave={() => this.setHoverState(false)}
            >
              {this.props.text}
            </animated.button>
        )}
      </Spring>
    );
  }
}