import {Spring, animated} from "react-spring";
import React from "react";
import { eventBus } from "./EventBus";

export default class NavbarButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false
    }
  }

  componentDidMount() {
    eventBus.on("sectionChange", (data) => this.setState({
      selected: data.newIndex === this.props.index
    }));
  }
  
  componentWillUnmount() {
    eventBus.remove("sectionChange");
  }

  render() {
    return (
      <Spring
        color={this.state.selected ? "rgba(89,54,4,1)" : "rgba(232, 226, 218, 1)"}
        // textDecorationThickness={this.state.selected ? "1.5vh" : "0vh"}
        // textDecorationColor={this.state.selected ? "rgba(230,157,41,1)" : "rgba(230,157,41,0)"}
        fontWeight={this.state.selected ? "800" : "500"}
        // padding={this.state.selected ? "0 1vw 0" : "0 0 0 1.5vw"}
        backgroundColor={this.state.selected ? "rgba(230,157,41,1)" : "rgba(230,157,41,0)"}
        // textDecorationLine={this.state.selected ? "underline" : "none"}
        config={{
          tension: 200,
          friction: 0,
          clamp: true
        }}
      >
        {(styles) => (
          <animated.li
            key={this.props.item}
            style={styles}
            className={this.state.selected ? "navbar-selected" : ""}
            onClick={() => {
              window.fullpage_api.moveTo(this.props.index + 1);
            }}
          >
            {this.props.item[0]}
          </animated.li>
        )}
      </Spring>
    );
  }
}