import React from "react";
import {Spring, animated, config} from "react-spring"
import "./Card.css"

export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hovered: false};
        this.sendBlogData.bind(this);
    }

    setHoverState = (state) => {
        this.setState({ hovered: state });
    };

    sendBlogData = () => {
        this.props.handleBlog(this.props.content);
    }

    render() {
        return (
            <Spring
            outlineColor={this.state.hovered ? "rgba(230,157,41,255)" : "rgba(20, 20, 20, 0.75)"}
            outlineWidth={this.state.hovered ? "0.35rem" : "0rem"}
            color={this.state.hovered ? "rgba(230,157,41,255)" : "rgba(232, 226, 218, 255)"}
            boxShadow={this.state.hovered ? "0 0 0.4rem 0.2rem rgba(230, 157, 41, 0.35) inset" : 
                                            "0 0 1rem 0.5rem rgba(0.1, 0.1, 0.1, 0.2) inset"}                   
            backgroundColor={this.state.hovered ? "rgba(89,54,4,255)" : "rgba(0.1, 0.1, 0.1, 0.25)"}
            config={{
                    tension: 150,
                    friction: 14,
                    clamp: true
            }}
            >
                {(styles) => (
                    <animated.div className='card'
                    style={{...styles, backgroundImage: `url(${this.props.thumbnail})`}} 
                    onMouseEnter={() => this.setHoverState(true)}
                    onMouseLeave={() => this.setHoverState(false)}
                    onClick={this.sendBlogData}
                    >
                        <Spring
                        color={this.state.hovered ? "rgba(255, 213, 153, 255)" : "rgba(232, 226, 218, 255)"}
                        backgroundColor={this.state.hovered ? "rgba(128, 77, 6, 0.5)" : "rgba(0.1, 0.1, 0.1, 0.5)"}
                        // fontWeight={this.state.hovered ? "900" : "600"}
                        config={{
                                tension: 150,
                                friction: 14,
                                clamp: true
                        }}
                        >
                            {(textStyle) => (
                                <animated.p style={textStyle} className="card-title">{this.props.title}</animated.p>
                            )}
                        </Spring>
                        {/* <p className="card-desc">{this.props.desc}</p> */}
                    </animated.div>
                )}
            </Spring>
        )
    }
}