import React from "react";
import { animated, Spring } from "react-spring";
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab, faGithub, faBehance, faMedium} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactButton.css"

library.add(fab, faGithub, faBehance, faMedium, faEnvelope);
export default class ContactButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hovered: false};
    }

    setHoverState = (state) => {
        this.setState({ hovered: state });
    };

    reroute = (link) => {
        if (link === undefined) return;
        window.open(link);
    }

    render() {
        return (
            <Spring
                color={this.state.hovered ? "rgba(250, 250, 250, 255)" : "rgba(100, 100, 100, 255)"}
                transform={this.state.hovered ? "translate(0, -1vh) scale(1.1)" : "translate(0, 0vh) scale(1)"}
            >
                {(styles) => (
                    <animated.div 
                        className="contact-btn-ctn"
                        ref={(btnRef) => (this.btn = btnRef)}
                        style={styles}
                        onClick={() => {this.reroute(this.props.link)}}
                        onMouseEnter={() => this.setHoverState(true)}
                        onMouseLeave={() => this.setHoverState(false)}
                    >
                        <FontAwesomeIcon className="glyphicon" style={styles} icon={this.props.icon} size={this.props.size}/>
                        <p className="contact-btn-text">
                        {this.props.text}
                        </p>
                    </animated.div>
                )}
            </Spring>
        )
    }
}