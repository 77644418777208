import React from "react";
import Card from "./Card";
import "./Blog.css";
import { Spring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretLeft } from "@fortawesome/free-solid-svg-icons";
// import { useScrollDirection } from "./useScrollDirection";

export default class Blog extends React.Component {
    _isMounted = false;
    BUCKET_SLUG = "portfolio-production-8cc70e90-ffe6-11ed-83e4-f1ae1c8fb2cc";
    READ_KEY = "NpEVHABLcrJtktKMOjChyiVPf3VolGvNTHhU8syiMo2kXqXPc6"

    constructor(props) {
        super(props);
        this.state = {
            blogs: [],
            scrollProgress: 0,
            caretConfig: {
                tension: 150,
                friction: 14,
                clamp: true
            },
            scrollable: false
        };
        this.scrollRef = React.createRef();
    }

    handleScroll = () => {
        var current = this.scrollRef.current;
        var width = current.scrollWidth - current.clientWidth;
        var pos = current.scrollLeft;
        var prog = (pos / width);
        this.setState({scrollProgress: prog});
        this.handleDirection();
    }

    handleDirection = () => {

    }

    isScrollable = () => {
        var current = this.scrollRef.current;
        return current.scrollWidth > current.clientWidth;
    }

    handleBlog = (content) => {
        this.props.displayBlog(content);
    }

    handleContent = (content) => {
        return content.toString().replaceAll(/https:\/\/cdn.cosmicjs.com\/(.*?)(png|jpg|mp4)/g, (match) => {
            return match.replace("cdn", "imgix") + "?auto=format,compress&w=1024&q=70";
        });
    }

    componentDidMount() {
        this._isMounted = true;
        fetch(`https://api.cosmicjs.com/v3/buckets/${this.BUCKET_SLUG}/objects?read_key=${this.READ_KEY}`)
        .then(resp => resp.json())
        .then(blogs => {
            if (this._isMounted) {
                this.setState({blogs: blogs["objects"]})
                // console.log(this.state.blogs);
                this.setState({scrollable: this.isScrollable()});
            }}
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return Object.keys(this.state.blogs).length > 0 ? (
            
                    <div className="blog">
                        <div className='blog-list-ctn' ref={this.scrollRef} onScroll={this.handleScroll}>
                            {this.state.blogs.map((item, idx) => {
                                if (item["metadata"]["tag"] === this.props.category) {
                                    return(<Card title={item["title"]} key={item["id"]} thumbnail={item["metadata"]["thumbnail"]["imgix_url"] + "?auto=format,compress"} content={this.handleContent(item["metadata"]["content"])} handleBlog={this.handleBlog}/>)
                                }
                            })}
                        </div>
                        <Spring
                            opacity={this.state.scrollProgress > 0.95 || this.state.scrollable === false ? 0 : 1}
                            config={this.state.caretConfig}
                        >
                            {(styles) => (
                                <animated.div style={styles} className="caret right-caret">
                                    <FontAwesomeIcon icon={faCaretRight}/>
                                </animated.div>
                            )}
                        </Spring>
                        <Spring
                            opacity={this.state.scrollProgress > 0.15 ? 1 : 0}
                            config={this.state.caretConfig}
                        >
                            {(styles) => (
                                <animated.div style={styles} className="caret left-caret">
                                    <FontAwesomeIcon icon={faCaretLeft}/>
                                </animated.div>
                            )}
                        </Spring>
                    </div>
        ) : (
            <div>
                <h2>Loading...</h2>
            </div>
        )
    }
}