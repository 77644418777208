import "./fonts/fonts.css";
import "./App.css";
import React from "react";
import Navbar from "./Navbar";
import Body from "./Body";
import BlogPanel from "./BlogPanel";
import AnimatedCursor from "react-animated-cursor";
import { eventBus } from "./EventBus";
import { Spring, animated } from "react-spring";

const navbarList = [
  ["Home", "#about"],
  ["featured", "#featured"],
  ["works", "#works"],
  ["contact", "#contact"],
];
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      navbarVisible: true,
      showBlogPanel: false,
      displayBlogPanel: false,
      wideNavbar: true,
      currentBlog: ""
    };
    this.showNavbar = this.showNavbar.bind(this);
    this.hideNavbar = this.hideNavbar.bind(this);
    eventBus.on("navbarWidth", (data) => this.updateNavbarWidth(data))
  }

  updateNavbarWidth(newState) {
    this.setState({
      wideNavbar: newState
    })
  }

  showNavbar() {
    this.setState({ navbarVisible: true });
  }

  hideNavbar() {
    this.setState({ navbarVisible: false });
  }

  displayBlog = (content) => {
    this.setState({displayBlogPanel: true});
    setTimeout(() => {
      this.setState({showBlogPanel: true, currentBlog: content});
      this.hideNavbar();
    }, 10)
  }

  hideBlog = () => {
    this.setState({showBlogPanel: false});
    this.showNavbar();
    setTimeout(() => {
      this.setState({displayBlogPanel: false});
    }, 10)
  }

  render() {
    return (
      <div>
        {/* <AnimatedCursor 
          color={"232, 226, 218"} 
          outerAlpha={0.25} 
          innerSize={18}
          innerStyle={{
            zIndex: 99999
          }}
          outerStyle={{
            zIndex: 99999
          }}
          outersize={24}
          trailingSpeed={1}
          clickables={[
            ".card", ".preview", ".btn", ".contact-btn-ctn"
          ]}
        /> */}
        <Spring
        width={this.state.wideNavbar ? "100vw" : "40vw"}
        padding={this.state.wideNavbar ? "4vh 6vw 0" : "4vh 0vw 0"}
        config={{mass:1, tension:180, friction:32}}
        >
          {styles => (
            <animated.div style={styles} className="Header">
              <Navbar
                items={navbarList}
                visible={this.state.navbarVisible}
              ></Navbar>
            </animated.div>
          )}
        </Spring>
        <Body
        showNavbar={this.showNavbar}
        hideNavbar={this.hideNavbar}
        displayBlog={this.displayBlog}
        />
        <BlogPanel 
        showState={this.state.showBlogPanel}
        displayState={this.state.displayBlogPanel}
        content={this.state.currentBlog}
        hideBlog={this.hideBlog}/>
      </div>
    );
  }
}
