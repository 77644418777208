import { animated, config, Spring } from "react-spring";
import ReactFullpage from "@fullpage/react-fullpage";
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab, faGithub, faBehance, faMedium} from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faCaretDown, faPhone, faMobile } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Suspense, useRef, useState, useEffect } from "react";
import Button from "./Button"
import Blog from "./Blog"
import ContactButton from "./contact/ContactButton";
import FeaturedList from "./FeaturedList";
import "./Body.css"
import { eventBus } from "./EventBus";
import {isMobile} from "react-device-detect";

library.add(fab, faGithub, faBehance, faMedium, faEnvelope, faPhone);

export default class Body extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showLanding: true,
        showFeatured: false,
        showFeaturedPanel: false,
        showBlog: false,
      };
    }

    resetState() {
      this.props.showNavbar();
      this.setState({
        showLanding: false,
        showFeatured: false,
        showFeaturedPanel: false,
        showBlog: false,
      });
    }

    onLeave(origin, destination, dir) {
      this.resetState();
      eventBus.dispatch("sectionChange", {newIndex: destination.index})
      eventBus.dispatch("navbarWidth", false);
      switch (destination.index) {
        case 0:
          // this.props.hideNavbar();
          this.setState({showLanding: true});
          eventBus.dispatch("navbarWidth", true)
          break;
        case 1:
          this.setState({showFeatured: true});
          break;
        case 2:
          this.setState({showBlog: true});
          break;
        default:
          break;
      }
    }

    componentDidMount() {
      eventBus.dispatch("sectionChange", {newIndex: 0})
    }

    componentWillUnmount() {
    }
  
    afterLoad(origin, destination, dir) {}
  
    render() {
      const { aspect } = window.innerWidth / window.innerHeight;
      return (
        <ReactFullpage
          onLeave={this.onLeave.bind(this)}
          afterLoad={this.afterLoad.bind(this)}
          keyboardScrolling={false}
          normalScrollElements={isMobile ? ".blog-panel, .blog-list-ctn" : ".blog-panel"}
          render={({ state, fullpageApi }) => {
            return (
              <div>
                <ReactFullpage.Wrapper>
                  <Spring
                  // background={this.state.showLanding ? "rgba(0,0,0,1)" : "rgba(20,20,20,1)"}
                  delay={200}
                  config={config.slow}
                  >
                    {bodyStyles => (
                      <animated.div className="main-body" style={bodyStyles}>
                        <div className="section" id="about">
                              <div className="about-content">
                                <Spring
                                  opacity={this.state.showLanding ? 1 : 0}
                                  transform={this.state.showLanding ? "translate(0vw,0)" : "translate(-75vw,0)"}
                                  delay={this.state.showLanding? 500 : 0}
                                  config={{mass:1, tension:200, friction:32, clamp:true}}
                                >
                                  {(infoStyles) => (
                                    <animated.div className="about-info" style={infoStyles}>
                                      <div className="about-text">
                                        <h1 className="Logo about">HUU QUANG NGUYEN</h1>
                                        <animated.p>
                                          I'm a <b>technical artist</b> specializing in writing shaders and creating assets.
                                        </animated.p>
                                        <ul>
                                          <li>Also a 3D artist & game developer.</li>
                                          <li>Unity, Unreal Engine, a bit of Godot.</li>
                                        </ul>
                                      </div>
                                      <div className="about-btn-ctn">
                                        <Button text={
                                          <div>
                                            Explore&nbsp;
                                            <FontAwesomeIcon icon={faCaretDown} size={"1x"}/>
                                          </div>
                                        } onClick={() => fullpageApi.moveTo(2)}/>
                                      </div>
                                    </animated.div>
                                  )}
                                </Spring>
                              </div>
                          <Spring opacity={this.state.showLanding ? 1 : 0} config={{mass:1, tension:240, friction:32}} delay={this.state.showLanding ? 500 : 0}>
                            {(styles) => (
                                  <animated.div className="about-canvas" style={styles}>
                                    <div className="vignette unselectable"></div>
                                    <video data-keepplaying autoPlay loop muted src="https://imgix.cosmicjs.com/f6b8bac0-df51-11ed-b6eb-0fc980522195-max.mp4?auto=compress&q=50"/>
                                  </animated.div>
                            )}
                          </Spring>
                        </div>
                        <div className="section" id="featured">
                        <Spring
                            opacity={this.state.showFeatured ? "1" : "0"}
                            delay={this.state.showFeatured ? 400 : 0}
                            config={{mass:1, tension:240, friction:32}}
                          >
                            {(blogStyles) => (
                              <animated.div className="blog-section-ctn" style={blogStyles}>
                                <h1>Featured</h1>
                                <FeaturedList displayBlog={this.props.displayBlog}/>
                              </animated.div>
                          )}
                          </Spring>
                        </div>
                        <div className="section" id="works">
                          <Spring
                            opacity={this.state.showBlog ? "1" : "0"}
                            // transform={this.state.showBlog ? "translate3d(0,0,0)" : "translate3d(0,-20%,0)"}
                            delay={this.state.showBlog ? 400 : 0}
                            config={{mass:1, tension:240, friction:32}}
                          >
                            {(blogStyles) => (
                              <animated.div className="works-blog-ctn">
                                <animated.div className="blog-section-ctn" style={blogStyles}>
                                  <h1>Previous works</h1>
                                  <h2>🎮 Game development</h2>
                                  <Blog category={"games"} displayBlog={this.props.displayBlog}/>
                                  {/* <br/> */}
                                  <h2>🛠 Game design</h2>
                                  <Blog category={"gd"} displayBlog={this.props.displayBlog}/>
                                  {/* <br/> */}
                                  <h2>📦 3D works</h2>
                                  <Blog category={"design"} displayBlog={this.props.displayBlog}/>
                                </animated.div>
                              </animated.div>
                          )}
                          </Spring>
                        </div>
                        <div className="section" id="contact">
                          <p>You can find me on other platforms such as:</p>
                          <div className="contact-ctn">
                            <ContactButton icon="envelope" link={"mailto:nguyenhuuquang2608@gmail.com"} text={"nguyenhuuquang2608@gmail.com"}></ContactButton>
                            <ContactButton icon={["fab", "github"]} link={"https://github.com/totenk0pf"} text={"totenk0pf"}></ContactButton>
                            <ContactButton icon={["fab", "linkedin"]} link={"https://www.linkedin.com/in/huuquang-ng/"} text={"Quang Nguyen"}></ContactButton>
                            <ContactButton icon={"phone"} link={"tel:+84931739800"} text={"+84 931 739 800"}></ContactButton>
                          </div>
                        </div>
                      </animated.div>
                    )}
                  </Spring>
                </ReactFullpage.Wrapper>
              </div>
            );
          }}
        />
      );
    }
}