import React from "react";
import { Spring, animated, config } from "react-spring";
import "./FeatureCard.css";

export default class FeatureCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hovered: false};
        this.sendBlogData.bind(this);
    }

    setHoverState = (state) => {
        this.setState({ hovered: state });
    };

    sendBlogData = () => {
        this.props.handleBlog(this.props.content);
    }

    render() {
        return (
            <Spring
            outlineColor={this.state.hovered ? "rgba(230,157,41,255)" : "rgba(30, 30, 30, 0.75)"}
            outlineWidth={this.state.hovered ? "0.35rem" : "0rem"}
            color={this.state.hovered ? "rgba(230,157,41,255)" : "rgba(232, 226, 218, 255)"}
            boxShadow={this.state.hovered ? "0 0 2rem 0.5rem rgba(230, 157, 41, 0.5) inset" : 
                                            "0 0 2rem 0.5rem rgba(0.1, 0.1, 0.1, 0.2) inset"}
            // fontWeight={this.state.hovered ? "600" : "400"}
            config={{
                    tension: 150,
                    friction: 14,
                    clamp: true
            }}
            >
                {(styles) => (
                    <animated.div className='preview'
                    // style={styles} 
                    onMouseEnter={() => this.setHoverState(true)}
                    onMouseLeave={() => this.setHoverState(false)}
                    onClick={this.sendBlogData}
                    > 
                        <animated.div 
                        className="feature-info" 
                        style={{...styles, backgroundImage: `url(${this.props.thumbnail})`}}
                        >
                            <div className="content">
                                <h1 className="feature-title">{this.props.title}</h1>
                                <p className="feature-desc">{this.props.desc}</p>
                            </div>
                        </animated.div>
                    </animated.div>
                )}
            </Spring>
        )
    }
}