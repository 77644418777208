import React from "react";
import FeatureCard from "./FeatureCard";
import "./FeaturedList.css";

export default class FeaturedList extends React.Component {
    _isMounted = false;
    BUCKET_SLUG = "portfolio-production-8cc70e90-ffe6-11ed-83e4-f1ae1c8fb2cc";
    READ_KEY = "NpEVHABLcrJtktKMOjChyiVPf3VolGvNTHhU8syiMo2kXqXPc6";

    constructor(props) {
        super(props);
        this.state = {
            blogs: []
        };
    }

    handleBlog = (content) => {
        this.props.displayBlog(content);
    }

    handleContent = (content) => {
        return content.toString().replaceAll(/https:\/\/cdn.cosmicjs.com\/(.*?)(png|jpg|mp4)/g, (match) => {
            return match.replace("cdn", "imgix") + "?auto=format,compress&w=1024&q=70";
        });
    }

    componentDidMount() {
        this._isMounted = true;
        fetch(`https://api.cosmicjs.com/v3/buckets/${this.BUCKET_SLUG}/objects?read_key=${this.READ_KEY}`)
        .then(resp => resp.json())
        .then(blogs => {
            if (this._isMounted) {
                this.setState({blogs: blogs["objects"]})
                // console.log(this.state.blogs);
            }}
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return Object.keys(this.state.blogs).length > 0 ? (
            <div className='feature-list-ctn'>
                {this.state.blogs.map((item, idx) => {
                    if (item["metadata"]["tag"] === "featured") {
                        return(<FeatureCard desc={item["metadata"]["description"]} title={item["title"]} key={item["id"]} thumbnail={item["metadata"]["thumbnail"]["imgix_url"] + "?auto=format,compress"} content={this.handleContent(item["metadata"]["content"])} handleBlog={this.handleBlog}/>)
                    }
                })}
            </div>
        ) : (
            <div>
                <h2>Loading...</h2>
            </div>
        )
    }
}