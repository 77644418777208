import React from "react";
import { animated, Spring } from "react-spring";
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "./Button"
import "./BlogPanel.css";

export default class BlogPanel extends React.Component {
    constructor(props) {
        super(props);
        this.ctnRef = React.createRef();
    }

    componentDidMount() {
        this.ctnRef.current.scrollTo(0,0);
    }

    handleBlog = () => {
        this.props.hideBlog();
        this.ctnRef.current.scrollTo(0,0);
    }

    render() {
        return (
            <Spring
                opacity={this.props.showState ? 1 : 0}
                backdropFilter={this.props.showState ? "blur(0rem)" : "blur(1rem)"}
            >
                {(styles) => (
                    <animated.div className="blog-panel" style={{styles, display: this.props.displayState ? "flex" : "none"}}>
                        <div className="bg"
                            onClick={this.handleBlog}
                        />
                        <div id="blog-content" className="content-ctn" ref={this.ctnRef}>
                            <Spring
                                opacity={this.props.showState ? 1 : 0}
                            >
                                {(panelStyles) => (
                                    <animated.div style={panelStyles} className="content" dangerouslySetInnerHTML={{__html: this.props.content}}/>
                                )}
                            </Spring>
                        </div>
                        <Button text={<FontAwesomeIcon icon={faTimes} size={"1x"} onClick={this.handleBlog}/>}/>
                    </animated.div>
                )}
            </Spring>
        );
    }
}